import React, { useEffect, useRef } from "react";
import Img_NepHairPin from "static/img/NepHairpin.png";
import styles from "./NepLoading.module.css";

//https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
let timeOrigin = 0;

function NepLoading({ width = 50, children = "", isShowChildren = true, loading, contentStyle = {} }) {
    const imgRef = useRef();
    useEffect(() => {
        let requestID;
        if (loading) {
            //velocity:turn/ms
            const velocity = 1 / (2 * 1000);
            let deltaTimestamp = 0;
            imgRef.current.style.transform = "rotat(0)";
            let rotatTurn = 0;
            function step(timestamp) {
                if (imgRef.current) {
                    deltaTimestamp = Math.min(timestamp - timeOrigin, 500);
                    rotatTurn += deltaTimestamp * velocity;
                    imgRef.current.style.transform = `rotate(${rotatTurn}turn)`;

                    timeOrigin = timestamp;
                    requestID = window.requestAnimationFrame(step);
                }
            }
            requestID = window.requestAnimationFrame(step);
        }
        return () => {
            requestID && cancelAnimationFrame(requestID);
        }
    }, [loading])

    return (
        <div className={styles.container}>
            <div className={loading ? `${styles.mask_wrapper} ${styles.mask_wrapper_show}` : `${styles.mask_wrapper} ${styles.mask_wrapper_hidden}`}>
                <div className={styles.mask}>
                    <div className={styles.mask_content}>
                        <img src={Img_NepHairPin} alt="NepLoading" ref={imgRef} style={{ width }} />
                    </div>
                </div>
            </div>
            <div className={styles.content} style={contentStyle}>
                {isShowChildren && children}
            </div>
        </div>
    )
}

export default NepLoading;