import React from "react";
import styles from "./PublicLayout.module.css";
import { Outlet } from "react-router-dom";
import PublicHeader from "./components/PublicHeader";
import Profile from "./components/Profile";

function PublicLayout() {
    return (
        <div className={styles.container_wrapper}>
            <div className={styles.container}>
                <PublicHeader />
                <div className={styles.content}>
                    <div className={styles.left}>
                        <Profile />
                    </div>
                    <div className={styles.right}>
                        <Outlet />
                        <div style={{ textAlign: "center", padding: "5px 0", fontSize: 14 }}>
                            <a href="https://beian.miit.gov.cn/" target="_blank" style={{ color: "rgb(210, 210, 210)" }}>蜀ICP备19037185号-2</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublicLayout;