import React from "react";
import styles from "./PublicHeader.module.css";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import NepFamily from "./NepFamily";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";

function PublicHeader() {
    const navigate = useNavigate();

    return (
        <div className={styles.header_wrapper}>
            <div className={styles.header}>
                <div className={styles.left}>
                    <NepFamily />
                </div>
                <div className={styles.right}>
                    <Nav />
                    <div className={styles.search}>
                        <Input className={styles.search_input} suffix={<SearchOutlined />} placeholder="input search text" onPressEnter={e => navigate(`/search?searchText=${e.target.value}`)} />
                        <span className={styles.search_icon} onClick={() => navigate(`/search`)}>
                            <SearchOutlined />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublicHeader;