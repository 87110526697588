import React from "react";
import Img_NepFamily from "static/img/NepFamily_1.png";
import { Link } from "react-router-dom";
import styles from "./NepFamily.module.css";

export default function ({ style = {} }) {
    return (
        <div className={styles.container}>
            <Link to="/">
                <img src={Img_NepFamily} alt="NepFamily" style={{ ...style }} />
            </Link>
        </div>
    )
}