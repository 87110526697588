import React from "react";
import styles from "./Profile.module.css";
import Img_NepAvatar from "static/img/NepAvatar.png";

function Profile() {

    return (
        <div className={styles.container}>
            <p><img src={Img_NepAvatar} alt="NepAvatar" /></p>
            <p>龚成炳</p>
            <p>四川</p>
            <p>涅普之家</p>
        </div>
    )
}

export default Profile;