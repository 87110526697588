import React, { useEffect, useState } from "react";
import * as services from "services/auth";
import { context } from "Context";
import { Outlet } from "react-router-dom";
import NepLoading from "components/NepLoading/NepLoading";

function SecurityLayout() {
    const [currUser, setCurrUser] = useState();

    useEffect(() => {
        services.getCurrUser().then(res => {
            // NepTesting
            // setTimeout(() => setCurrUser({ name: "neptune" }), 1000);
            if (res.data) {
                setTimeout(() => setCurrUser(res.data), 1000);
            }
            else {
                setTimeout(() => window.location.href = process.env.REACT_APP_LOGIN_URL, 1000);
            }
        }).catch(() => { })
    }, [])

    return (
        <>
            {
                currUser && (
                    <context.Provider value={{ currUser, setCurrUser }}>
                        <Outlet />
                    </context.Provider>
                ) || <NepLoading loading={true} width={150} />
            }
        </>
    )
}

export default SecurityLayout;