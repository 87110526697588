import request from "utils/request";

export const getCurrUser = () => {
    return request("/auth/getCurrUser", { method: "GET" });
}

export const login = (body) => {
    return request("/auth/login", { method: "POST", body });
}

export const logout = () => {
    return request("/auth/logout", { method: "GET" });
}