import React, { useEffect, useState } from "react";
import styles from "./Nav.module.css";
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

export default function () {
    const [navShow, setNavShow] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (navShow) {
            //获取屏幕宽度
            let width = document.body.offsetWidth;
            if (width <= 1000) {
                document.body.style.overflow = "hidden";
            }
        }
        else {
            document.body.style.overflow = "auto";
        }
    }, [navShow])

    useEffect(() => {
        setNavShow(false);
    }, [location])

    return (
        <>
            <div className={styles.nav}>
                <div className={styles.nav_full_screen}>
                    <span><Link to="/home">首页</Link></span>
                    <span><Link to="/type">分类</Link></span>
                    <span>关于</span>
                </div>
                <div className={styles.nav_middle_screen_wrapper}>
                    {
                        navShow ? (
                            <span>
                                <CloseOutlined onClick={() => setNavShow(!navShow)} />
                                <div className={styles.nav_middle_screen}>
                                    <Link to="/home">首页</Link>
                                    <Link to="/type">分类</Link>
                                    <a>关于</a>
                                </div>
                            </span>
                        ) : <MenuOutlined onClick={() => setNavShow(!navShow)} />
                    }
                </div>
            </div >
        </>
    )
}