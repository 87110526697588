import React from "react";
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from "react-router-dom";
import AdminLayout from "layout/Admin/AdminLayout";
import SecurityLayout from "layout/SecurityLayout";
import PublicLayout from "layout/Public/PublicLayout";
import withAsyncComponent from "components/withAsyncComponent";
import NepNotFound from "components/NepNotFound/NepNotFound";
import Live2dLayout from "layout/Live2dLayout";

function App() {
  const AsyncAdminHome = withAsyncComponent(() => import("./page/Admin/Home/AdminHome"));
  const AsyncLogPublishing = withAsyncComponent(() => import("./page/Admin/LogPublishing/LogPublishing"));
  const AsyncLogin = withAsyncComponent(() => import("./page/Login"));
  const AsyncLogManagement = withAsyncComponent(() => import("./page/Admin/LogManagement/LogManagement"));
  const AsyncPublicHome = withAsyncComponent(() => import("./page/Public/Home/Home"));
  const AsyncType = withAsyncComponent(() => import("./page/Public/Type/Type"));
  const AsyncSearch = withAsyncComponent(() => import("./page/Public/Search/Search"));
  const AsyncLog = withAsyncComponent(() => import("./page/Public/Log/Log"));
  const AsyncNepTools = withAsyncComponent(() => import("./page/Admin/NepTools/NepTools"));

  const router = (
    createBrowserRouter(
      createRoutesFromElements(
        <>
          <Route path="/admin" element={<SecurityLayout />}>
            <Route path="/admin/" element={<AdminLayout />}>
              <Route index element={<Navigate to="/admin/home" />} />
              <Route path="home" element={<AsyncAdminHome />} />
              <Route path="log-publishing" element={<AsyncLogPublishing />} />
              <Route path="log-management" element={<AsyncLogManagement />} />
              <Route path="neptools" element={<AsyncNepTools />} />
              <Route path="*" element={<Navigate to="/admin/home" />} />
            </Route>
          </Route>
          <Route path="/login" element={<AsyncLogin />} />
          <Route path="/" element={<Live2dLayout />}>
            <Route path="/search" element={<AsyncSearch />} />
            <Route path="/log/:year/:month/:logTitleEn" element={<AsyncLog />} />
            <Route path="/" element={<PublicLayout />}>
              <Route index element={<Navigate to="/home" />} />
              <Route path="/home" element={<AsyncPublicHome />} />
              <Route path="/type" element={<AsyncType />} />
            </Route>
          </Route>
          <Route path="*" element={<NepNotFound />} />
        </>
      )
    )
  )

  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App;