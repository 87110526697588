import React from "react";
import Img_NepNotFound from "static/img/NepNotFound.png";

function NotFound() {
    return (
        <div id="notFound" style={{ width: "100%", height: "100%", backgroundColor: "white" }}>
            <div style={{ padding: "50px" }}>
                <div>
                    <img src={Img_NepNotFound} alt="" style={{ display: "block", margin: "0 auto" }} />
                </div>
                <h1 style={{ textAlign: "center" }}>Nepヽ（≧□≦）ノ NotFound</h1>
            </div>
        </div >
    )
}

export default NotFound;