import React, { useEffect, useRef, useState } from "react";
import { draggableHtmlElement } from "utils/utils";
import styles from "./Live2dControlPanel.module.css";
import Img_NepMark from "static/img/NepMark.png";
import Live2dModelSettingPanel from "./Live2dModelSettingPanel";

export default function Live2dControlPanel({ canvasRef, modelSettingParams }) {
    const [showModelSettingPanel, setShowModelSettingPanel] = useState(false);
    const draggableArea = useRef();

    useEffect(() => {
        draggableHtmlElement(draggableArea.current);
    }, [])

    useEffect(() => {
        if (!showModelSettingPanel) {
            // 重置控制图标位置
            draggableArea.current.style.right = "15px";
            draggableArea.current.style.bottom = "15px";
        }
    }, [showModelSettingPanel])

    return (
        <div>
            {
                showModelSettingPanel && (
                    <Live2dModelSettingPanel
                        modelSettingParams={modelSettingParams}
                        canvas={canvasRef.current}
                        setShowModelSettingPanel={setShowModelSettingPanel}
                    />
                )
            }
            <div className={styles.panel_mark} ref={draggableArea}>
                <img src={Img_NepMark} alt="NepMark" className={styles.nepmark} onClick={() => setShowModelSettingPanel(!showModelSettingPanel)} />
            </div>
        </div>
    )
}