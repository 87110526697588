import React from "react";
import NepLoading from "./NepLoading/NepLoading";

export default function withAsyncComponent(importComponent, LoadingComponent) {
    class AsyncComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            importComponent().then(res => {
                this.setState({ component: res.default });
            })
        }

        render() {
            const Component = this.state.component || LoadingComponent;
            return Component ? <Component /> : <NepLoading loading={true} />
        }
    }
    return AsyncComponent;
}