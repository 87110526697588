import React, { useEffect, useRef, useState } from "react";
import Live2dControlPanel from "./Live2d/Live2dControlPanel";
import { Outlet } from "react-router-dom";

function Live2dLayout() {
    const canvasRef = useRef();
    const [isMounted, setIsMounted] = useState(false);
    const [isShowLive2d, setIsShowLive2d] = useState(false);
    const modelSettingParams = useRef({ scale: 1, left: 0, bottom: -100 });

    useEffect(() => {
        // 屏幕宽大于1000显示live2d
        // if (window.screen.availWidth > 1000 && window.live2d) {
        //     setIsShowLive2d(true);
        // }
        setIsShowLive2d(true);
        setIsMounted(true);
    }, [])

    useEffect(() => {
        if (isMounted && isShowLive2d) {
            if (canvasRef.current) {
                window.live2d.run(canvasRef.current);
                window.onbeforeunload = window.live2d.release;
            }
            return () => window.live2d.release();
        }
    }, [isMounted, isShowLive2d])

    if (!isMounted) return null;

    return (
        <>
            <Outlet />
            {
                isShowLive2d && (
                    <div id="live2d">
                        <canvas id="live2d-model" ref={canvasRef} style={{ position: "fixed", zIndex: 999, left: modelSettingParams.current.left, bottom: modelSettingParams.current.bottom }} />
                        <div id="live2d-control-panel">
                            <Live2dControlPanel canvasRef={canvasRef} modelSettingParams={modelSettingParams.current} />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Live2dLayout;