export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener("load", e => resolve(e.target.result));
        fileReader.addEventListener("error", err => reject(err));
    })
}

export const datetimeFormat = (datetime) => {
    datetime = datetime ? new Date(datetime) : new Date();
    let year = datetime.getFullYear();
    let month = `0${datetime.getMonth() + 1}`.slice(-2);
    let date = `0${datetime.getDate()}`.slice(-2);
    let hour = `0${datetime.getHours()}`.slice(-2);
    let minute = `0${datetime.getMinutes()}`.slice(-2);
    return `${year}/${month}/${date} ${hour}:${minute}`;
}

export const draggableHtmlElement = (draggableElement, targetElements = [], fixed = true, targetElementParent) => {
    if (!draggableElement) {
        return;
    }
    if (!targetElements.length) {
        targetElements[0] = draggableElement;
    }
    let _captured = false;
    let startPosX, startPosY;
    let relTops = [], relLefts = [];

    const resetRelPos = () => {
        if (!fixed && targetElementParent) {
            for (let i = 0; i < targetElements.length; i++) {
                relTops[i] = targetElements[i].getBoundingClientRect().top - targetElementParent.getBoundingClientRect().top;
                relLefts[i] = targetElements[i].getBoundingClientRect().left - targetElementParent.getBoundingClientRect().left;
            }
        }
        else {
            for (let i = 0; i < targetElements.length; i++) {
                relTops[i] = targetElements[i].getBoundingClientRect().top;
                relLefts[i] = targetElements[i].getBoundingClientRect().left;
            }
        }
    }

    draggableElement.addEventListener("mousedown", e => {
        document.body.style.userSelect = "none";
        _captured = true;
        startPosX = e.x;
        startPosY = e.y;
        resetRelPos();
    })

    draggableElement.addEventListener("mousemove", e => {
        if (_captured) {
            for (let i = 0; i < targetElements.length; i++) {
                targetElements[i].style.top = (relTops[i] + (e.y - startPosY)) + "px";
                targetElements[i].style.left = (relLefts[i] + (e.x - startPosX)) + "px";
            }
        }
    })

    draggableElement.addEventListener("mouseup", () => {
        _captured = false;
        document.body.style.userSelect = "auto";
        resetRelPos();
    })

    draggableElement.addEventListener("mouseleave", () => {
        _captured = false;
        document.body.style.userSelect = "auto";
        resetRelPos();
    })
}