import React from "react";
import { Outlet, useLocation, useNavigate, Link } from "react-router-dom";
import styles from "./AdminLayout.module.css";
import Img_NepFamily from "static/img/NepFamily_1.png";
import { Menu, message } from "antd";
import { HomeOutlined, LogoutOutlined, FormOutlined, SnippetsOutlined, ToolOutlined } from "@ant-design/icons";
import * as services from "services/auth";

function AdminLayout() {
    const location = useLocation();
    const navigate = useNavigate();

    const path = location.pathname.split("/");
    const selectedKeys = [path.pop()];

    const menuItems = [
        {
            label: "首页",
            key: "home",
            icon: <HomeOutlined />
        },
        {
            label: "日志管理",
            key: "log-management",
            icon: <SnippetsOutlined />
        },
        {
            label: "日志",
            key: "log-publishing",
            icon: <FormOutlined />
        },
        {
            label: "工具箱",
            key: "neptools",
            icon: <ToolOutlined />
        },
        {
            label: "注销",
            key: "logout",
            icon: <LogoutOutlined />
        }
    ];

    const handleMenuClick = (e) => {
        let key = e.key;
        if (key == "logout") {
            services.logout().then(() => {
                message.success("注销成功！");
                setTimeout(() => window.location.href = process.env.REACT_APP_LOGIN_URL, 1000);
            })
        }
        else {
            navigate(key);
        }
    }

    return (
        <div className={styles.container_wrapper}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.slogan}>
                        <Link to="/home">
                            <img src={Img_NepFamily} alt="NepFamily" />
                        </Link>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <Menu
                            items={menuItems}
                            onClick={handleMenuClick}
                            selectedKeys={selectedKeys}
                        />
                    </div>
                    <div className={styles.right}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLayout;