import React, { useEffect, useRef } from "react";
import { draggableHtmlElement } from "utils/utils";
import styles from "./Live2dModelSettingPanel.module.css";
import { CloseOutlined, RedoOutlined } from "@ant-design/icons";
import ValueChooser from "./ValueChooser";

function Live2dModelSettingPanel({ canvas, setShowModelSettingPanel, modelSettingParams }) {
    const draggableAreaForPanel = useRef();
    const draggableAreaForPanelAndModel = useRef();
    const panelRef = useRef();

    useEffect(() => {
        draggableHtmlElement(draggableAreaForPanel.current, [panelRef.current]);
        draggableHtmlElement(draggableAreaForPanelAndModel.current, [canvas, panelRef.current]);
        resetSetting();
    }, [])

    // 重置模型控制面板位置
    const resetModelSettingPanelPosition = () => {
        panelRef.current.style.top = canvas.getBoundingClientRect().top;
        panelRef.current.style.left = canvas.getBoundingClientRect().right;
        let modelWidth = canvas.offsetWidth;
        let modelHeight = canvas.offsetHeight;
        let panelWidth = panelRef.current.offsetWidth;
        let panelHeight = panelRef.current.offsetHeight;
        if (modelWidth > panelWidth) {
            panelRef.current.style.left = canvas.getBoundingClientRect().left + ((modelWidth - panelWidth) / 2) + "px";
            panelRef.current.style.top = canvas.getBoundingClientRect().top + ((modelHeight - panelHeight) / 2) + "px";
        }
    }

    // 重置设置参数
    const resetSetting = () => {
        resize(1);
        //重置模型位置
        canvas.style.left = modelSettingParams.left + "px";
        canvas.style.bottom = modelSettingParams.bottom + "px";
        canvas.style.top = "auto";
        resetModelSettingPanelPosition();
    }

    // 模型尺寸改变
    const resize = (newScale) => {
        window.live2d.resize(newScale);
        modelSettingParams.scale = newScale;
    }

    return (
        <div className={styles.model_setting} ref={panelRef}>
            <div ref={draggableAreaForPanel} className={styles.header}>
                <span>Live2d Model Setting</span>
                <span>
                    <RedoOutlined style={{ cursor: "pointer", marginRight: "5px" }} onClick={resetSetting} />
                    <CloseOutlined style={{ cursor: "pointer" }} onClick={() => setShowModelSettingPanel(false)} />
                </span>
            </div>
            <div className={styles.tools}>
                <div ref={draggableAreaForPanelAndModel} className={styles.draggable}>
                    拖拽移动
                </div>
                <ValueChooser name="缩放" min={0} max={4} defaultValue={1} value={modelSettingParams.scale} onChange={v => resize(v)} />
            </div>
        </div>
    )
}

export default Live2dModelSettingPanel;