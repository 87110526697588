import { message } from "antd";

const SERVICE_BASE = process.env.REACT_APP_SERVICE_BASE;
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

function Proxy(api, options, raw , needAuth) {
    return (
        fetch(`${SERVICE_BASE}${api}`, { ...options, credentials: 'include' })
            .then((res) => {
                if (raw) {
                    return res;
                }
                else {
                    if (res.status == 401) {
                        //跳转授权
                        if (needAuth) {
                            window.location.href = LOGIN_URL;
                        }
                        else {
                            return Promise.reject();
                        }
                    }
                    else {
                        return res.text().then(text => {
                            let body = text;
                            if (text) {
                                try {
                                    body = JSON.parse(text);
                                }
                                catch (err) { }
                            }

                            if (res.status != 200 && Math.floor(res.status / 200) == 2) {
                                //4xx或5xx状态码需要进行错误处理
                                return Promise.reject(body.message);
                            }
                            else {
                                //其它状态码
                                return Promise.resolve(body);
                            }
                        })
                    }
                }
            })
            .catch(err => {
                if (err) {
                    err = err.toString();
                }
                message.error(err);
                return Promise.reject();
            })
    )
}

function addParamToUrl(url, params = {}) {
    let i = 0;
    for (let p in params) {
        if (params[p] === null || params[p] === undefined) {
            break;
        }
        url = i === 0 ? url + '?' : url + '&';
        url += p + '=' + params[p];
        i++;
    }
    return url;
}

function postFormData(params = {}) {
    let formdata = new FormData();
    for (let p in params) {
        if (Array.isArray(params[p]) && params[p][0] instanceof File) {
            params[p].forEach(value => {
                formdata.append(p, value);
            })
        }
        else {
            formdata.append(p, params[p]);
        }
    }
    return formdata;
}

export const RequestGet = function (api, params = {}, headers = {}, raw, needAuth) {
    api = addParamToUrl(api, params);
    return Proxy(api, { method: "GET", headers }, raw, needAuth);
}

export const RequestPostWithFormData = function (api, body = {}, params = {}, headers = {}, raw, needAuth) {
    api = addParamToUrl(api, params);
    return Proxy(api, { method: "POST", body: postFormData(body), headers }, raw, needAuth);
}

export const RequestPostWithJson = function (api, params = {}, body = {}, headers = {}, raw, needAuth) {
    api = addParamToUrl(api, params);
    for (let key in body) {
        if (body[key] === null || body[key] === undefined) {
            delete body[key];
        }
    }
    return Proxy(api, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...headers
        },
        body: JSON.stringify(body)
    }, raw, needAuth)
}
/*
    @params api
    @params options
    {
        method,
        params,
        body,
        headers
    }
    @params needAuth(bool) : 是否需要进行身份验证
    @params raw(bool) ： 是否获取原始的reponse响应结果
*/
export default function (api, options, needAuth = false, raw = false) {
    if (options.method == "GET") {
        return RequestGet(api, options.params, options.headers, raw, needAuth)
    }
    else if (options.method == "POST") {
        return RequestPostWithJson(api, options.params, options.body, options.headers, raw, needAuth)
    }
}