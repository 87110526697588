import React, { useEffect, useRef } from "react";
import styles from "./ValueChooser.module.css";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

function ValueChooser({ min = 0, max = 1, defaultValue = 0, value, name, onChange }) {
    const progressRef = useRef();
    const barRef = useRef();
    const valueEleRef = useRef();
    const valueRange = max - min;
    const deltaValue = 0.01;

    const handleValueChange = (value, e) => {
        if (typeof value == "number") {
            if (value === value) {
                let ratio = (value - min) / valueRange;
                barRef.current.style.left = ((ratio * progressRef.current.offsetWidth) - (barRef.current.offsetWidth / 2)) + "px";
                triggerChange(value);
            }
        }
        else if (e) {
            let ratio = (e.x - progressRef.current.getBoundingClientRect().left) / progressRef.current.offsetWidth;
            ratio = Math.max(Math.min(ratio, 1), 0);
            barRef.current.style.left = ((ratio * progressRef.current.offsetWidth) - (barRef.current.offsetWidth / 2)) + "px";
            let v = min + (ratio * valueRange);
            triggerChange(v);
        }
    }

    useEffect(() => {
        let captured = false;
        progressRef.current.onclick = (e) => handleValueChange(null, e);
        barRef.current.onmousedown = (e) => captured = true;
        barRef.current.onmousemove = (e) => captured && handleValueChange(null, e);
        barRef.current.onmouseup = () => captured = false;
        barRef.current.onmouseleave = () => captured = false;
        if (value >= min && value <= max) {
            handleValueChange(value);
        }
        else {
            handleValueChange(defaultValue);
        }
    }, [])

    const triggerChange = (value) => {
        valueEleRef.current.innerText = value.toFixed(2);
        typeof onChange == "function" && onChange(value);
    }

    const handleManualChange = (value, factor = 0) => {
        value = typeof value == "number" ? value : parseFloat(valueEleRef.current.innerText);
        if (value === value) {
            value = value + deltaValue * factor;
            value = Math.max(Math.min(value, max), min);
            handleValueChange(value * 1);
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.manual}>
                <span>{name}</span>
                <div className={styles.manual_changer}>
                    <CaretLeftOutlined onClick={() => handleManualChange(null, -1)} />
                    <span ref={valueEleRef} onClick={() => handleManualChange(defaultValue, 0)} />
                    <CaretRightOutlined onClick={() => handleManualChange(null, 1)} />
                </div>
            </div>
            <div className={styles.progress} ref={progressRef}>
                <span className={styles.bar} ref={barRef}></span>
            </div>
        </div>
    )
}

export default ValueChooser;